

import Link from 'src/components/router/link';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import logoMark from 'src/images/glide-mark-purple.svg';
import { Spin } from 'antd';
import { inject } from 'mobx-react';

const clsPrefix = 'app-full-spinner';

function FullSpinner({ style, showOverTopBar, showNav, ui }) {
  return (
    <div
      className={classNames(clsPrefix, {
        [`${clsPrefix}--over-top-bar`]: !!showOverTopBar,
      })}
      style={style}
    >
      {!!showNav && !ui.isGlideMobileApp && !ui.isCompass && (
        <div className={`${clsPrefix}__nav-home-container`}>
          <Link className={`${clsPrefix}__logo`} routeName="home">
            <img
              alt="Glide logo"
              src={logoMark}
              style={{
                width: '32px',
                height: '32px',
              }}
            />
          </Link>
          <Link routeName="home">
            <span className={`${clsPrefix}__nav-home-label`}>Home</span>
          </Link>
        </div>
      )}
      <Spin size="large" />
    </div>
  );
}

FullSpinner.propTypes = {
  style: PropTypes.any,
  showOverTopBar: PropTypes.bool,
  showNav: PropTypes.bool,
  ui: PropTypes.object.isRequired,
};

FullSpinner.defaultProps = {
  showNav: true,
};

export default inject('ui')(FullSpinner);
