import React, { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { observer } from 'mobx-react';

export default function fetchData(
  getterFetcher,
  mountFetchMode = 'auto',
  updateFetchMode = 'auto'
) {
  function decorator(Wrapped) {
    @observer
    class FetchData extends Component {
      state = {}; // gets rid of warning

      render() {
        const props = this.props;
        const data = getterFetcher(false, props);
        const newProps = { ...props, ...data };
        return <Wrapped {...newProps} />;
      }

      componentDidMount() {
        getterFetcher(mountFetchMode, this.props);
      }

      componentDidUpdate() {
        getterFetcher(updateFetchMode, this.props);
      }
    }

    const componentName =
      FetchData.displayName || FetchData.name || 'Component';
    FetchData.displayName = `FetchData(${componentName})`;
    return hoistNonReactStatics(FetchData, Wrapped);
  }

  return decorator;
}
