

import NavLogo from 'src/components/common/nav-logo';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';

const clsPrefix = 'app-top-bar';

export const TOP_BAR_HEIGHT = '64px';

function NavMenuItem({ children }) {
  return <div className={`${clsPrefix}__nav-menu-item`}>{children}</div>;
}

NavMenuItem.propTypes = {
  children: PropTypes.any,
};

@inject('ui')
@observer
export default class TopBar extends Component {
  static NavMenuItem = NavMenuItem;

  static propTypes = {
    className: PropTypes.string,
    onMenuClick: PropTypes.func,
    children: PropTypes.any,
    showLogo: PropTypes.bool,
    ui: PropTypes.object.isRequired,
    navMenuItems: PropTypes.any,
    search: PropTypes.any,
    theme: PropTypes.object,
    purpleLogo: PropTypes.bool,
    homeRouteName: PropTypes.string,
    href: PropTypes.string,
    isCompass: PropTypes.bool,
  };

  static defaultProps = {
    showLogo: true,
    homeRouteName: 'home',
  };

  handleMenuClick = (e) => {
    if (this.props.onMenuClick) {
      this.props.onMenuClick(e);
    }
  };

  selectedKeys() {
    const selectedKeys = [];
    return selectedKeys;
  }

  render() {
    const {
      className,
      showLogo,
      children,
      homeRouteName,
      href,
      navMenuItems,
      purpleLogo,
      search,
      theme: customTheme,
      ui,
      isCompass,
    } = this.props;
    const { theme: uiTheme } = ui;
    const theme = customTheme || uiTheme;
    const clsName = classNames(clsPrefix, className, {
      [`${clsPrefix}--no-theme`]: !theme && !isCompass,
      [`${clsPrefix}--theme`]: !!theme && !isCompass,
      [`${clsPrefix}--compass`]: isCompass,
      // hide top bar if it's ios app or if Glide is embedded
      [`${clsPrefix}--mobile`]: ui.isGlideMobileApp,
      [`${clsPrefix}--embedded`]: ui.isEmbedded,
    });
    if (window.Glide.browser === 'webkit') {
      return null;
    }
    let style = {};
    if (theme && theme.color) {
      style = {
        borderBottomColor: theme.color,
        borderBottomWidth: '4px',
        borderBottomStyle: 'solid',
        height: TOP_BAR_HEIGHT,
      };
    }

    let logoVariant;
    if (isCompass) {
      logoVariant = 'compass';
    } else if (purpleLogo && theme) {
      logoVariant = 'purple';
    } else {
      logoVariant = theme ? 'theme' : 'default';
    }
    return (
      <div className={clsName} style={style}>
        <div className={`${clsPrefix}__inset`}>
          <div className="app-top-bar__left">
            {showLogo && (
              <NavLogo
                variant={logoVariant}
                routeName={homeRouteName}
                href={href}
                ariaLabel={`${isCompass ? 'Compass' : 'Glide'} logo`}
              />
            )}
            {search && <div className={`${clsPrefix}__search`}>{search}</div>}
            {navMenuItems && navMenuItems.length > 0 && (
              <div className={`${clsPrefix}__nav`}>{navMenuItems}</div>
            )}
          </div>
          <div className="app-top-bar__right">{children}</div>
        </div>
      </div>
    );
  }
}
